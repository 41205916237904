import "./App.css";
import { AuthProvider, useDescope, useSession, useUser } from "@descope/react-sdk";
import { SignUpOrInFlow } from "@descope/react-sdk";
import React, { useState } from "react";

const AppRoot = () => {
	return (
		<AuthProvider
			projectId="P2NQiPfsldwmWEUCCsRWMGzOT4cn"
			//  baseUrl="<URL>" // you can also configure the baseUrl ex: https://auth.company.com  - this is useful when you utilize CNAME within your Descope project.
		>
			<App />
		</AuthProvider>
	);
};

function App() {
	const { isAuthenticated, isSessionLoading } = useSession();

	// user: user object with all the user details such as email, name etc.
	// isUserLoading: boolean - Use this for showing loading screens while objects are being loaded
	const { user, isUserLoading } = useUser();

	// logout - call logout to logout the user (deletes all session state)
	const { logout } = useDescope();

	const [isLoading, setIsLoading] = useState(true);

	if (isAuthenticated) {
		return (
			<>
				<div className="done">
					<img className="device" src="k.png" alt="Success" />
					<p>
						Pre-order successfully completed by
						<br />
						{user?.email || ""}
						<br />
						<button class="backButton" href="#" onClick={logout}>
							back
						</button>
					</p>
				</div>
			</>
		)
	}
	return (
		<div className="App">
			{(isLoading || isSessionLoading )&& 
				<div className="done">
					<p>Loading...</p>
				</div>
			}

			{
				<SignUpOrInFlow
					onReady={() => {
						setIsLoading(false);
					}}
					onSuccess={(e) => {
						const obj = {
							firstName: e.detail.user.name ? e.detail.user.name.split(" ")[0] : "",
							lastName: e.detail.user.name ? e.detail.user.name.split(" ").splice(1).join(" ") : "",
							name: e.detail.user.name,
							email: e.detail.user.email,
							userId: e.detail.user.userId,
							origin: "AuthentiBlood",
						};

						window.analytics.identify(e.detail.user.userId, obj);
						window.analytics.track("pre_order_success", obj);
					}}
					onError={(e) => console.log("Could not log in!")}
					theme="dark"
				/>
			}
		</div>
	);
}

export default AppRoot;
